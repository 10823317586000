import React from 'react'
//import { Link } from 'gatsby';
import Link from '../components/Link'

import Layout from "../components/layout"
import SEO from "../components/seo"

const PaymentSuccess = () => (
  <Layout>
    <SEO title="Payment Success" />
    <h1>Sucess!</h1>
    <Link to="/login">let's sign in and start</Link>
  </Layout>
)

export default PaymentSuccess